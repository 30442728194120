import {
  BundleType,
  GlobalItem,
  GlobalItemGroups,
} from '@/shared/types/common';

export const mockedData: GlobalItem[] = [
  {
    rare: 'legendary',
    type: 'egg',
    realName: 'royal_egg',
    game: 'adopt',
    variant: null,
    name: 'Royal Egg',
    subtype: null,
    img: 'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/royal_egg_218155608038015716861250016536746955046.png',
    products: [
      {
        id: 27271,
        price: 3.22,
        prices: [3.22, 3.22, 3.22],
        maxCount: 3,
        age: null,
        flyable: null,
        rideable: null,
        pumping: null,
      },
    ],
  },
  {
    rare: 'common',
    type: 'transport',
    game: 'adopt',
    variant: null,
    realName: 'neon_black_scooter',
    name: 'Neon Black Scooter',
    subtype: 'scooters',
    img: 'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/neon_black_scooter_181279737671050341577192616449886507435.png',
    products: [
      {
        id: 27448,
        price: 43.32,
        age: null,
        flyable: null,
        rideable: null,
        pumping: null,
        prices: [43.32, 43.32, 43.32, 43.32],
        maxCount: 4,
      },
    ],
  },
  {
    rare: 'uncommon',
    type: 'pet',
    game: 'adopt',
    variant: null,
    realName: 'urban_2023_tawny_frogmouth',
    name: 'Tawny Frogmouth',
    subtype: null,
    img: 'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/urban_2023_tawny_frogmouth_119331012819097045543021857508892653948.png',
    products: [
      {
        id: 13573,
        price: 144,
        prices: [144, 144, 144, 144],
        maxCount: 4,
        age: 'newborn',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 13574,
        price: 47.2,
        prices: [47.2, 47.2, 47.2, 47.2, 47.2, 47.2],
        maxCount: 6,
        age: 'newborn',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
    ],
  },
  {
    rare: 'uncommon',
    type: 'pet',
    game: 'adopt',
    variant: null,
    realName: 'basic_egg_2022_camel',
    name: 'Camel',
    subtype: null,
    img: 'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/basic_egg_2022_camel_136141241958015511182421130414687560343.png',
    products: [
      {
        id: 23597,
        price: 33,
        prices: [33, 33, 33, 33, 33, 33, 33, 33, 33],
        maxCount: 9,
        age: 'reborn',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 23620,
        price: 38,
        prices: [38],
        maxCount: 1,
        age: 'luminous',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
    ],
  },
  {
    rare: 'uncommon',
    type: 'pet',
    game: 'adopt',
    variant: null,
    realName: 'halloween_2021_black_mummy_cat',
    name: 'Halloween Black Mummy Cat',
    subtype: null,
    img: 'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/halloween_2021_black_mummy_cat_53705332753530036708937767565677110703.png',
    products: [
      {
        id: 33573,
        price: 144,
        prices: [144, 144, 144, 144],
        maxCount: 4,
        age: 'newborn',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
    ],
  },
  {
    rare: 'uncommon',
    type: 'pet',
    game: 'adopt',
    variant: null,
    realName: 'basic_egg_2022_poodle',
    name: 'Poodle',
    subtype: null,
    img: 'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/basic_egg_2022_poodle_10009173492233461134036485935467892038.png',
    products: [
      {
        id: 43597,
        price: 33,
        prices: [33, 33, 33, 33, 33, 33, 33, 33, 33],
        maxCount: 9,
        age: 'reborn',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
    ],
  },
];

export const mockedItemsGroups: GlobalItemGroups[] = [
  {
    rare: 'legendary',
    type: 'egg',
    realName: 'royal_egg',
    name: 'Royal Egg',
    game: 'adopt',
    description:
      'The Carpet Zombie Ponycycle is a limited legendary pet in Adopt Me! that was released on June 16, 2022. It can be obtained through hatching a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that its respective event has ended, it can only be obtained through trading.',
    subtype: null,
    imageUri:
      'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/royal_egg_218155608038015716861250016536746955046.png',
    products: [
      {
        id: 27271,
        price: 3.22,
        prices: [3.22, 3.22, 3.22, 4.5, 5.5, 10.99],
        maxCount: 3,
        age: null,
        flyable: null,
        rideable: null,
        pumping: null,
      },
    ],
  },
  {
    rare: 'common',
    type: 'transport',
    game: 'adopt',
    realName: 'neon_black_scooter',
    name: 'Neon Black Scooter',
    subtype: 'scooters',
    description:
      'The Carpet Zombie Ponycycle is a limited legendary pet in Adopt Me! that was released on June 16, 2022. It can be obtained through hatching a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that its respective event has ended, it can only be obtained through trading.',

    imageUri:
      'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/neon_black_scooter_181279737671050341577192616449886507435.png',
    products: [
      {
        id: 27448,
        price: 43.32,
        age: null,
        flyable: null,
        rideable: null,
        pumping: null,
        prices: [43.32, 43.32, 43.32, 43.32],
        maxCount: 4,
      },
    ],
  },
  {
    rare: 'ultra_rare',
    type: 'pet',
    realName: 'urban_2023_tawny_frogmouth',
    name: 'Tawny Frogmouth',
    game: 'adopt',
    description:
      'The Carpet Zombie Ponycycle is a limited legendary pet in Adopt Me! that was released on June 16, 2022. It can be obtained through hatching a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that its respective event has ended, it can only be obtained through trading.',
    subtype: null,
    imageUri:
      'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/urban_2023_tawny_frogmouth_119331012819097045543021857508892653948.png',
    products: [
      {
        id: 13576,
        price: 1.0,
        prices: [1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.21],
        maxCount: 9,
        age: 'newborn',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 13620,
        price: null,
        prices: null,
        maxCount: null,
        age: 'luminous',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 13584,
        price: null,
        prices: null,
        maxCount: null,
        age: 'pre_teen',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 13578,
        price: 1.99,
        prices: [1.99],
        maxCount: 1,
        age: 'junior',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 13588,
        price: null,
        prices: null,
        maxCount: null,
        age: 'teen',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 13596,
        price: null,
        prices: null,
        maxCount: null,
        age: 'full_grown',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 13615,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sunshine',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 13579,
        price: null,
        prices: null,
        maxCount: null,
        age: 'junior',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 13573,
        price: 144,
        prices: [144, 144, 144, 144],
        maxCount: 4,
        age: 'newborn',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 13613,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sunshine',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 13607,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sparkle',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 13608,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sparkle',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 13611,
        price: null,
        prices: null,
        maxCount: null,
        age: 'flare',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 13574,
        price: 47.2,
        prices: [47.2, 47.2, 47.2, 47.2, 47.2, 47.2],
        maxCount: 6,
        age: 'newborn',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 13612,
        price: null,
        prices: null,
        maxCount: null,
        age: 'flare',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 13624,
        price: null,
        prices: null,
        maxCount: null,
        age: null,
        flyable: false,
        rideable: false,
        pumping: 'mega_neon',
      },
      {
        id: 13577,
        price: null,
        prices: null,
        maxCount: null,
        age: 'junior',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 13575,
        price: null,
        prices: null,
        maxCount: null,
        age: 'newborn',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 13599,
        price: null,
        prices: null,
        maxCount: null,
        age: 'reborn',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 13586,
        price: null,
        prices: null,
        maxCount: null,
        age: 'teen',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 13595,
        price: null,
        prices: null,
        maxCount: null,
        age: 'full_grown',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 13582,
        price: null,
        prices: null,
        maxCount: null,
        age: 'pre_teen',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 13581,
        price: null,
        prices: null,
        maxCount: null,
        age: 'pre_teen',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 13609,
        price: null,
        prices: null,
        maxCount: null,
        age: 'flare',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 13614,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sunshine',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 13621,
        price: null,
        prices: null,
        maxCount: null,
        age: null,
        flyable: true,
        rideable: true,
        pumping: 'mega_neon',
      },
      {
        id: 13610,
        price: null,
        prices: null,
        maxCount: null,
        age: 'flare',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 13623,
        price: null,
        prices: null,
        maxCount: null,
        age: null,
        flyable: false,
        rideable: true,
        pumping: 'mega_neon',
      },
      {
        id: 13594,
        price: null,
        prices: null,
        maxCount: null,
        age: 'full_grown',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 13591,
        price: null,
        prices: null,
        maxCount: null,
        age: 'post_teen',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 13605,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sparkle',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 13602,
        price: null,
        prices: null,
        maxCount: null,
        age: 'twinkle',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 13587,
        price: null,
        prices: null,
        maxCount: null,
        age: 'teen',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 13583,
        price: null,
        prices: null,
        maxCount: null,
        age: 'pre_teen',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 13606,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sparkle',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 13618,
        price: null,
        prices: null,
        maxCount: null,
        age: 'luminous',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 13592,
        price: null,
        prices: null,
        maxCount: null,
        age: 'post_teen',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 13600,
        price: null,
        prices: null,
        maxCount: null,
        age: 'reborn',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 13598,
        price: null,
        prices: null,
        maxCount: null,
        age: 'reborn',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 13590,
        price: null,
        prices: null,
        maxCount: null,
        age: 'post_teen',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 13619,
        price: null,
        prices: null,
        maxCount: null,
        age: 'luminous',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 13580,
        price: null,
        prices: null,
        maxCount: null,
        age: 'junior',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 13585,
        price: null,
        prices: null,
        maxCount: null,
        age: 'teen',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 13589,
        price: null,
        prices: null,
        maxCount: null,
        age: 'post_teen',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 13601,
        price: null,
        prices: null,
        maxCount: null,
        age: 'twinkle',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 13603,
        price: null,
        prices: null,
        maxCount: null,
        age: 'twinkle',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 13604,
        price: null,
        prices: null,
        maxCount: null,
        age: 'twinkle',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 13593,
        price: null,
        prices: null,
        maxCount: null,
        age: 'full_grown',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 13616,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sunshine',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 13617,
        price: null,
        prices: null,
        maxCount: null,
        age: 'luminous',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 13622,
        price: null,
        prices: null,
        maxCount: null,
        age: null,
        flyable: true,
        rideable: false,
        pumping: 'mega_neon',
      },
      {
        id: 13597,
        price: null,
        prices: null,
        maxCount: null,
        age: 'reborn',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
    ],
  },
  {
    rare: 'uncommon',
    type: 'pet',
    game: 'adopt',
    realName: 'basic_egg_2022_camel',
    name: 'Camel',
    subtype: null,
    description:
      'The Carpet Zombie Ponycycle is a limited legendary pet in Adopt Me! that was released on June 16, 2022. It can be obtained through hatching a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that its respective event has ended, it can only be obtained through trading.',
    imageUri:
      'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/basic_egg_2022_camel_136141241958015511182421130414687560343.png',
    products: [
      {
        id: 23576,
        price: null,
        prices: null,
        maxCount: null,
        age: 'newborn',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 23620,
        price: 38,
        prices: [38],
        maxCount: 1,
        age: 'luminous',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 23584,
        price: null,
        prices: null,
        maxCount: null,
        age: 'pre_teen',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 23578,
        price: null,
        prices: null,
        maxCount: null,
        age: 'junior',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 23588,
        price: 41,
        prices: [41, 41, 41],
        maxCount: 3,
        age: 'teen',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 23596,
        price: 30,
        prices: [30, 30, 30, 30, 30, 30],
        maxCount: 6,
        age: 'full_grown',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 23615,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sunshine',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 23579,
        price: null,
        prices: null,
        maxCount: null,
        age: 'junior',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 23573,
        price: 144,
        prices: [144, 144, 144, 144],
        maxCount: 4,
        age: 'newborn',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 23613,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sunshine',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 23607,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sparkle',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 23608,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sparkle',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 23611,
        price: null,
        prices: null,
        maxCount: null,
        age: 'flare',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 23574,
        price: 47.2,
        prices: [47.2, 47.2, 47.2, 47.2, 47.2, 47.2],
        maxCount: 6,
        age: 'newborn',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 23612,
        price: null,
        prices: null,
        maxCount: null,
        age: 'flare',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 23624,
        price: 10,
        prices: [10, 10],
        maxCount: 2,
        age: null,
        flyable: false,
        rideable: false,
        pumping: 'mega_neon',
      },
      {
        id: 23577,
        price: null,
        prices: null,
        maxCount: null,
        age: 'junior',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 23575,
        price: 1.22,
        prices: [1.22, 1.22, 1.22],
        maxCount: 3,
        age: 'newborn',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 23599,
        price: 22.4,
        prices: [22.4, 22.4, 22.4, 22.4],
        maxCount: 4,
        age: 'reborn',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 23586,
        price: 33.22,
        prices: [3.22, 3.22],
        maxCount: 2,
        age: 'teen',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 23595,
        price: 11.44,
        prices: [11.44, 11.44, 11.44, 11.44, 11.44],
        maxCount: 5,
        age: 'full_grown',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 23582,
        price: 0.22,
        prices: [0.22],
        maxCount: 1,
        age: 'pre_teen',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 23581,
        price: null,
        prices: null,
        maxCount: null,
        age: 'pre_teen',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 23609,
        price: null,
        prices: null,
        maxCount: null,
        age: 'flare',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 23614,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sunshine',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 23621,
        price: 44.2,
        prices: [44.2, 44.2, 44.2, 44.2],
        maxCount: 4,
        age: null,
        flyable: true,
        rideable: true,
        pumping: 'mega_neon',
      },
      {
        id: 23610,
        price: null,
        prices: null,
        maxCount: null,
        age: 'flare',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 23623,
        price: 99.9,
        prices: [99.9, 99.9, 99.9, 99.9],
        maxCount: 4,
        age: null,
        flyable: false,
        rideable: true,
        pumping: 'mega_neon',
      },
      {
        id: 23594,
        price: null,
        prices: null,
        maxCount: null,
        age: 'full_grown',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 23591,
        price: 33.41,
        prices: [33.41, 33.41, 33.41, 33.41, 33.41],
        maxCount: 5,
        age: 'post_teen',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 23605,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sparkle',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 23602,
        price: null,
        prices: null,
        maxCount: null,
        age: 'twinkle',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 23587,
        price: null,
        prices: null,
        maxCount: null,
        age: 'teen',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 23583,
        price: null,
        prices: null,
        maxCount: null,
        age: 'pre_teen',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 23606,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sparkle',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 23618,
        price: 23.54,
        prices: [23.54, 23.54, 23.54],
        maxCount: 3,
        age: 'luminous',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 23592,
        price: null,
        prices: null,
        maxCount: null,
        age: 'post_teen',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 23600,
        price: 32.99,
        prices: [32.99, 32.99, 32.99, 32.99, 32.99],
        maxCount: 5,
        age: 'reborn',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 23598,
        price: null,
        prices: null,
        maxCount: null,
        age: 'reborn',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 23590,
        price: 28.43,
        prices: [28.43, 28.43, 28.43],
        maxCount: 3,
        age: 'post_teen',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 23619,
        price: null,
        prices: null,
        maxCount: null,
        age: 'luminous',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 23580,
        price: null,
        prices: null,
        maxCount: null,
        age: 'junior',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 23585,
        price: 28.41,
        prices: [28.41, 28.41, 28.41],
        maxCount: 3,
        age: 'teen',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 23589,
        price: null,
        prices: null,
        maxCount: null,
        age: 'post_teen',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 23601,
        price: 28.49,
        prices: [28.49, 28.49, 28.49, 28.49],
        maxCount: 4,
        age: 'twinkle',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 23603,
        price: 1,
        prices: [1, 1, 1, 1, 1, 1, 1],
        maxCount: 7,
        age: 'twinkle',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 23604,
        price: 2.2,
        prices: [2.2],
        maxCount: 1,
        age: 'twinkle',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 23593,
        price: null,
        prices: null,
        maxCount: null,
        age: 'full_grown',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 23616,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sunshine',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 23617,
        price: null,
        prices: null,
        maxCount: null,
        age: 'luminous',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 23622,
        price: 91.2,
        prices: [91.2, 91.2],
        maxCount: 2,
        age: null,
        flyable: true,
        rideable: false,
        pumping: 'mega_neon',
      },
      {
        id: 23597,
        price: 33,
        prices: [33, 33, 33, 33, 33, 33, 33, 33, 33],
        maxCount: 9,
        age: 'reborn',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
    ],
  },
  {
    rare: 'uncommon',
    type: 'pet',
    game: 'adopt',
    realName: 'halloween_2021_black_mummy_cat',
    name: 'Halloween Black Mummy Cat',
    subtype: null,
    description:
      'The Carpet Zombie Ponycycle is a limited legendary pet in Adopt Me! that was released on June 16, 2022. It can be obtained through hatching a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that its respective event has ended, it can only be obtained through trading.',
    imageUri:
      'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/halloween_2021_black_mummy_cat_53705332753530036708937767565677110703.png',
    products: [
      {
        id: 33576,
        price: null,
        prices: null,
        maxCount: null,
        age: 'newborn',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 33620,
        price: 38,
        prices: [38],
        maxCount: 1,
        age: 'luminous',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 33584,
        price: null,
        prices: null,
        maxCount: null,
        age: 'pre_teen',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 33578,
        price: null,
        prices: null,
        maxCount: null,
        age: 'junior',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 33588,
        price: 41,
        prices: [41, 41, 41],
        maxCount: 3,
        age: 'teen',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 33596,
        price: 30,
        prices: [30, 30, 30, 30, 30, 30],
        maxCount: 6,
        age: 'full_grown',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 33615,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sunshine',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 33579,
        price: null,
        prices: null,
        maxCount: null,
        age: 'junior',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 33573,
        price: 144,
        prices: [144, 144, 144, 144],
        maxCount: 4,
        age: 'newborn',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 33613,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sunshine',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 33607,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sparkle',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 33608,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sparkle',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 33611,
        price: null,
        prices: null,
        maxCount: null,
        age: 'flare',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 33574,
        price: 47.2,
        prices: [47.2, 47.2, 47.2, 47.2, 47.2, 47.2],
        maxCount: 6,
        age: 'newborn',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 33612,
        price: null,
        prices: null,
        maxCount: null,
        age: 'flare',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 33624,
        price: 10,
        prices: [10, 10],
        maxCount: 2,
        age: null,
        flyable: false,
        rideable: false,
        pumping: 'mega_neon',
      },
      {
        id: 33577,
        price: null,
        prices: null,
        maxCount: null,
        age: 'junior',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 33575,
        price: 1.22,
        prices: [1.22, 1.22, 1.22],
        maxCount: 3,
        age: 'newborn',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 33599,
        price: 22.4,
        prices: [22.4, 22.4, 22.4, 22.4],
        maxCount: 4,
        age: 'reborn',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 33586,
        price: 33.22,
        prices: [3.22, 3.22],
        maxCount: 2,
        age: 'teen',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 33595,
        price: 11.44,
        prices: [11.44, 11.44, 11.44, 11.44, 11.44],
        maxCount: 5,
        age: 'full_grown',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 33582,
        price: 0.22,
        prices: [0.22],
        maxCount: 1,
        age: 'pre_teen',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 33581,
        price: null,
        prices: null,
        maxCount: null,
        age: 'pre_teen',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 33609,
        price: null,
        prices: null,
        maxCount: null,
        age: 'flare',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 33614,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sunshine',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 33621,
        price: 44.2,
        prices: [44.2, 44.2, 44.2, 44.2],
        maxCount: 4,
        age: null,
        flyable: true,
        rideable: true,
        pumping: 'mega_neon',
      },
      {
        id: 33610,
        price: null,
        prices: null,
        maxCount: null,
        age: 'flare',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 33623,
        price: 99.9,
        prices: [99.9, 99.9, 99.9, 99.9],
        maxCount: 4,
        age: null,
        flyable: false,
        rideable: true,
        pumping: 'mega_neon',
      },
      {
        id: 33594,
        price: null,
        prices: null,
        maxCount: null,
        age: 'full_grown',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 33591,
        price: 33.41,
        prices: [33.41, 33.41, 33.41, 33.41, 33.41],
        maxCount: 5,
        age: 'post_teen',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 33605,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sparkle',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 33602,
        price: null,
        prices: null,
        maxCount: null,
        age: 'twinkle',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 33587,
        price: null,
        prices: null,
        maxCount: null,
        age: 'teen',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 33583,
        price: null,
        prices: null,
        maxCount: null,
        age: 'pre_teen',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 33606,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sparkle',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 33618,
        price: 23.54,
        prices: [23.54, 23.54, 23.54],
        maxCount: 3,
        age: 'luminous',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 33592,
        price: null,
        prices: null,
        maxCount: null,
        age: 'post_teen',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 33600,
        price: 32.99,
        prices: [32.99, 32.99, 32.99, 32.99, 32.99],
        maxCount: 5,
        age: 'reborn',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 33598,
        price: null,
        prices: null,
        maxCount: null,
        age: 'reborn',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 33590,
        price: 28.43,
        prices: [28.43, 28.43, 28.43],
        maxCount: 3,
        age: 'post_teen',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 33619,
        price: null,
        prices: null,
        maxCount: null,
        age: 'luminous',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 33580,
        price: null,
        prices: null,
        maxCount: null,
        age: 'junior',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 33585,
        price: 28.41,
        prices: [28.41, 28.41, 28.41],
        maxCount: 3,
        age: 'teen',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 33589,
        price: null,
        prices: null,
        maxCount: null,
        age: 'post_teen',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 33601,
        price: 28.49,
        prices: [28.49, 28.49, 28.49, 28.49],
        maxCount: 4,
        age: 'twinkle',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 33603,
        price: 1,
        prices: [1, 1, 1, 1, 1, 1, 1],
        maxCount: 7,
        age: 'twinkle',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 33604,
        price: 2.2,
        prices: [2.2],
        maxCount: 1,
        age: 'twinkle',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 33593,
        price: null,
        prices: null,
        maxCount: null,
        age: 'full_grown',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 33616,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sunshine',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 33617,
        price: null,
        prices: null,
        maxCount: null,
        age: 'luminous',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 33622,
        price: 91.2,
        prices: [91.2, 91.2],
        maxCount: 2,
        age: null,
        flyable: true,
        rideable: false,
        pumping: 'mega_neon',
      },
      {
        id: 33597,
        price: 33,
        prices: [33, 33, 33, 33, 33, 33, 33, 33, 33],
        maxCount: 9,
        age: 'reborn',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
    ],
  },
  {
    rare: 'uncommon',
    type: 'pet',
    game: 'adopt',
    realName: 'basic_egg_2022_poodle',
    name: 'Poodle',
    subtype: null,
    description:
      'The Carpet Zombie Ponycycle is a limited legendary pet in Adopt Me! that was released on June 16, 2022. It can be obtained through hatching a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that its respective event has ended, it can only be obtained through trading.',
    imageUri:
      'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/basic_egg_2022_poodle_10009173492233461134036485935467892038.png',
    products: [
      {
        id: 43576,
        price: null,
        prices: null,
        maxCount: null,
        age: 'newborn',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 43620,
        price: 38,
        prices: [38],
        maxCount: 1,
        age: 'luminous',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 43584,
        price: null,
        prices: null,
        maxCount: null,
        age: 'pre_teen',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 43578,
        price: null,
        prices: null,
        maxCount: null,
        age: 'junior',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 43588,
        price: 41,
        prices: [41, 41, 41],
        maxCount: 3,
        age: 'teen',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 43596,
        price: 30,
        prices: [30, 30, 30, 30, 30, 30],
        maxCount: 6,
        age: 'full_grown',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 43615,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sunshine',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 43579,
        price: null,
        prices: null,
        maxCount: null,
        age: 'junior',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 43573,
        price: 144,
        prices: [144, 144, 144, 144],
        maxCount: 4,
        age: 'newborn',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 43613,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sunshine',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 43607,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sparkle',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 43608,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sparkle',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 43611,
        price: null,
        prices: null,
        maxCount: null,
        age: 'flare',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 43574,
        price: 47.2,
        prices: [47.2, 47.2, 47.2, 47.2, 47.2, 47.2],
        maxCount: 6,
        age: 'newborn',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 43612,
        price: null,
        prices: null,
        maxCount: null,
        age: 'flare',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 43624,
        price: 10,
        prices: [10, 10],
        maxCount: 2,
        age: null,
        flyable: false,
        rideable: false,
        pumping: 'mega_neon',
      },
      {
        id: 43577,
        price: null,
        prices: null,
        maxCount: null,
        age: 'junior',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 43575,
        price: 1.22,
        prices: [1.22, 1.22, 1.22],
        maxCount: 3,
        age: 'newborn',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 43599,
        price: 22.4,
        prices: [22.4, 22.4, 22.4, 22.4],
        maxCount: 4,
        age: 'reborn',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 43586,
        price: 33.22,
        prices: [3.22, 3.22],
        maxCount: 2,
        age: 'teen',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 43595,
        price: 11.44,
        prices: [11.44, 11.44, 11.44, 11.44, 11.44],
        maxCount: 5,
        age: 'full_grown',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 43582,
        price: 0.22,
        prices: [0.22],
        maxCount: 1,
        age: 'pre_teen',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 43581,
        price: null,
        prices: null,
        maxCount: null,
        age: 'pre_teen',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 43609,
        price: null,
        prices: null,
        maxCount: null,
        age: 'flare',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 43614,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sunshine',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 43621,
        price: 44.2,
        prices: [44.2, 44.2, 44.2, 44.2],
        maxCount: 4,
        age: null,
        flyable: true,
        rideable: true,
        pumping: 'mega_neon',
      },
      {
        id: 43610,
        price: null,
        prices: null,
        maxCount: null,
        age: 'flare',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 43623,
        price: 99.9,
        prices: [99.9, 99.9, 99.9, 99.9],
        maxCount: 4,
        age: null,
        flyable: false,
        rideable: true,
        pumping: 'mega_neon',
      },
      {
        id: 43594,
        price: null,
        prices: null,
        maxCount: null,
        age: 'full_grown',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 43591,
        price: 33.41,
        prices: [33.41, 33.41, 33.41, 33.41, 33.41],
        maxCount: 5,
        age: 'post_teen',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 43605,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sparkle',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 43602,
        price: null,
        prices: null,
        maxCount: null,
        age: 'twinkle',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 43587,
        price: null,
        prices: null,
        maxCount: null,
        age: 'teen',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 43583,
        price: null,
        prices: null,
        maxCount: null,
        age: 'pre_teen',
        flyable: false,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 43606,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sparkle',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 43618,
        price: 23.54,
        prices: [23.54, 23.54, 23.54],
        maxCount: 3,
        age: 'luminous',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 43592,
        price: null,
        prices: null,
        maxCount: null,
        age: 'post_teen',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 43600,
        price: 32.99,
        prices: [32.99, 32.99, 32.99, 32.99, 32.99],
        maxCount: 5,
        age: 'reborn',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 43598,
        price: null,
        prices: null,
        maxCount: null,
        age: 'reborn',
        flyable: true,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 43590,
        price: 28.43,
        prices: [28.43, 28.43, 28.43],
        maxCount: 3,
        age: 'post_teen',
        flyable: true,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 43619,
        price: null,
        prices: null,
        maxCount: null,
        age: 'luminous',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 43580,
        price: null,
        prices: null,
        maxCount: null,
        age: 'junior',
        flyable: false,
        rideable: false,
        pumping: 'default',
      },
      {
        id: 43585,
        price: 28.41,
        prices: [28.41, 28.41, 28.41],
        maxCount: 3,
        age: 'teen',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 43589,
        price: null,
        prices: null,
        maxCount: null,
        age: 'post_teen',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 43601,
        price: 28.49,
        prices: [28.49, 28.49, 28.49, 28.49],
        maxCount: 4,
        age: 'twinkle',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 43603,
        price: 1,
        prices: [1, 1, 1, 1, 1, 1, 1],
        maxCount: 7,
        age: 'twinkle',
        flyable: false,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 43604,
        price: 2.2,
        prices: [2.2],
        maxCount: 1,
        age: 'twinkle',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 43593,
        price: null,
        prices: null,
        maxCount: null,
        age: 'full_grown',
        flyable: true,
        rideable: true,
        pumping: 'default',
      },
      {
        id: 43616,
        price: null,
        prices: null,
        maxCount: null,
        age: 'sunshine',
        flyable: false,
        rideable: false,
        pumping: 'neon',
      },
      {
        id: 43617,
        price: null,
        prices: null,
        maxCount: null,
        age: 'luminous',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
      {
        id: 43622,
        price: 91.2,
        prices: [91.2, 91.2],
        maxCount: 2,
        age: null,
        flyable: true,
        rideable: false,
        pumping: 'mega_neon',
      },
      {
        id: 43597,
        price: 33,
        prices: [33, 33, 33, 33, 33, 33, 33, 33, 33],
        maxCount: 9,
        age: 'reborn',
        flyable: true,
        rideable: true,
        pumping: 'neon',
      },
    ],
  },
];
// export const mockedMM2Bundles: BundleType[] = [
//   {
//     realName: 'card-random-chroma-bundle-1020',
//     propertiesSetId: 'card-random-chroma-bundle-1020',
//     item: 'chroma-bundles',
//     chromaSetId: null,
//     category: 'mm2-bundles',
//     game: 'mm2',
//     rarity: 'ancient',
//     variant: 'chroma',
//     tagCategory: [],
//     info: {
//       title: 'Full Chroma Elderwood Bundle',
//       price: {
//         current: 11,
//         old: 25,
//       },
//       age: 'none',
//     },
//     description:
//       'The Carpet Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
//       ' that was released on June 16, 2022. It can be obtained through hatching' +
//       ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
//       ' its respective event has ended, it can only be obtained through trading.',
//     tagsInfo: [
//       { variant: 'discount', text: '-30%' },
//       { variant: 'hot-price', text: 'Hot Price' },
//     ],
//   },
//   {
//     id: 'card-random-bundle-1020',
//     propertiesSetId: 'card-random-bundle-1020',
//     chromaSetId: 'card-random-chroma-bundle-1020',
//     item: 'bundles',
//     category: 'mm2-bundles',
//     game: 'mm2',
//     rarity: 'ancient',
//     variant: null,
//     tagCategory: [],
//     info: {
//       title: 'Full Elderwood Bundle',
//       price: {
//         current: 11,
//         old: 25,
//       },
//       age: 'none',
//     },
//     description:
//       'The Carpet Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
//       ' that was released on June 16, 2022. It can be obtained through hatching' +
//       ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
//       ' its respective event has ended, it can only be obtained through trading.',
//     tagsInfo: [
//       { variant: 'discount', text: '-30%' },
//       { variant: 'hot-price', text: 'Hot Price' },
//     ],
//   },
//   {
//     id: 'card-random-bundle-1021',
//     propertiesSetId: 'card-random-bundle-1021',
//     item: 'bundles',
//     category: 'mm2-bundles',
//     game: 'mm2',
//     chromaSetId: null,
//     rarity: 'uncommon',
//     variant: null,
//     tagCategory: [],
//     info: {
//       title: 'Full Elderwood Bundle',
//       price: {
//         current: 14,
//         old: 25,
//       },
//       age: 'none',
//     },
//     description:
//       'The Carpet Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
//       ' that was released on June 16, 2022. It can be obtained through hatching' +
//       ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
//       ' its respective event has ended, it can only be obtained through trading.',
//     tagsInfo: [
//       { variant: 'discount', text: '-30%' },
//       { variant: 'hot-price', text: 'Hot Price' },
//     ],
//   },
//   {
//     id: 'card-random-1020',
//     propertiesSetId: 'card-random-1020',
//     item: 'bundles',
//     chromaSetId: null,
//     category: 'mm2-bundles',
//     game: 'mm2',
//     rarity: 'rare',
//     variant: null,
//     tagCategory: [],
//     info: {
//       title: 'Full Elderwood Bundle',
//       price: {
//         current: 23,
//         old: 25,
//       },
//       age: 'none',
//     },
//     description:
//       'The Carpet Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
//       ' that was released on June 16, 2022. It can be obtained through hatching' +
//       ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
//       ' its respective event has ended, it can only be obtained through trading.',
//     tagsInfo: [
//       { variant: 'discount', text: '-30%' },
//       { variant: 'hot-price', text: 'Hot Price' },
//     ],
//   },
//   {
//     id: 'card-random-bundle-1022',
//     propertiesSetId: 'card-random-bundle-1022',
//     item: 'bundles',
//     category: 'mm2-bundles',
//     game: 'mm2',
//     chromaSetId: null,
//     rarity: 'common',
//     variant: null,
//     tagCategory: [],
//     info: {
//       title: 'Full Elderwood Bundle',
//       price: {
//         current: 23,
//         old: 25,
//       },
//       age: 'none',
//     },
//     description:
//       'The Carpet Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
//       ' that was released on June 16, 2022. It can be obtained through hatching' +
//       ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
//       ' its respective event has ended, it can only be obtained through trading.',
//     tagsInfo: [
//       { variant: 'discount', text: '-30%' },
//       { variant: 'hot-price', text: 'Hot Price' },
//     ],
//   },
// ];
